import gql from "graphql-tag";
import { apolloClient } from "@/plugins/vue-apollo";

export default function (assetUuid: string, propertyName: string, aliasName: string | null = null): Promise<boolean> {
  return apolloClient
    .mutate({
      mutation: gql`
        mutation ($assetUuid: ID!, $propertyName: String!, $aliasName: String) {
          setPropertyAlias(assetUuid: $assetUuid, propertyName: $propertyName, aliasName: $aliasName)
        }
      `,
      variables: {
        assetUuid,
        propertyName,
        aliasName
      }
    })
    .then(() => {
      return true;
    });
}
